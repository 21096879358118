import React, { useEffect, useMemo, useRef } from 'react';
import cn from 'classnames';
import { isEqual } from 'lodash';

import * as UPLOAD_CONSTANTS from '@/Framework/UI/Organisms/Upload/constants';
import { UploadPreview } from '@/Framework/UI/Organisms/Upload/components';
import { IFinalFormFieldInputComponentProps } from '@/Framework/UI/Organisms/FinalForm/interfaces';
import { TUploadMethod, TUploadPreviewFormValue } from './interfaces';
import files from '@/Framework/url/filesUrl';

import useUploadImage from './useUploadImage';

import { FormError, FormField, ImgFetching } from '@dealroadshow/uikit';

import styles from './uploadImageInput.scss';
import uploadStyles from '@/Framework/UI/Organisms/Upload/upload.scss';
import formStyles from '@/Framework/UI/Organisms/FinalForm/form.scss';

interface IProps extends IFinalFormFieldInputComponentProps<TUploadPreviewFormValue> {
  label: string,
  touchedError?: true,
  uploadMethod: TUploadMethod,
  shouldUrlStartWithSlash?: boolean,
  errorClassName?: string,
  urlHelper?: { [key: string]: any },
  uploadName?: string,
  allowedFileText?: string,
  dropzoneContainerCls?: string,
  uploadPreviewCls?: string,
}

const UploadImageInput = (
  {
    label,
    input,
    meta,
    uploadMethod,
    touchedError,
    maxSizeInfo,
    shouldUrlStartWithSlash = true,
    errorClassName,
    urlHelper,
    uploadName,
    allowedFileText,
    dropzoneContainerCls,
    uploadPreviewCls,
    ...otherProps
  }: IProps,
) => {
  const shouldImageInit = useRef(false);

  const {
    state: uploadedImageState,
    initUploadImage,
    uploadImage,
    uploadImageCancel,
  } = useUploadImage(input.name, uploadMethod);

  const uploadedImage = useMemo(() => {
    let { url = '' } = uploadedImageState;
    if (shouldUrlStartWithSlash && url && !url.startsWith('/')) {
      url = `/${ url }`;
    }

    return {
      ...uploadedImageState,
      url,
      preview: {
        url,
      },
    };
  }, [uploadedImageState]);

  useEffect(
    () => {
      if (input.value) {
        shouldImageInit.current = true;
        initUploadImage(input.value);
      }
    },
    [meta.initial],
  );

  useEffect(
    () => {
      if (uploadedImage && !input.value) {
        uploadImageCancel();
      }
    },
    [input.value],
  );

  useEffect(
    () => {
      if (shouldImageInit.current && !uploadedImage.url) {
        return;
      }

      let uploadedImageFormValue = null;
      shouldImageInit.current = false;

      if (uploadedImage.uploaded && uploadedImage.canSave) {
        uploadedImageFormValue = {
          name: uploadedImage.name || null,
          url: uploadedImage.url,
        };
        if (uploadedImage.initialFile) {
          uploadedImageFormValue.name = uploadedImageFormValue.name || uploadedImage.initialFile?.name;
        }
      }

      if (!isEqual(input.value, uploadedImageFormValue)) {
        input.onChange(uploadedImageFormValue);
      }
    },
    [uploadedImage.url],
  );

  const getFilePreviewUrl = (params) => {
    if (urlHelper) {
      return urlHelper.getFileUrl(params);
    }
    return files.getFileUrl(params);
  };

  return (
    <FormField>
      <div className={ formStyles.formLabel }>
        { label }
      </div>
      <UploadPreview
        urlHelper={ urlHelper }
        uploadName={ uploadName }
        uploadFile={ uploadedImage }
        allowedFileTypes={ UPLOAD_CONSTANTS.UPLOAD_LOGO_ALLOWED_FILES }
        allowedFileText={ allowedFileText || UPLOAD_CONSTANTS.UPLOAD_LOGO_ALLOWED_FILES_TEXT }
        formFieldName={ input.name }
        onUpload={ uploadImage }
        onUploadCancel={ uploadImageCancel }
        dropzoneClassName={ cn(styles.dropzoneContainer, dropzoneContainerCls, {
          [styles.error]:
          (touchedError || meta.touched) && (meta.error || (!meta.modifiedSinceLastSubmit && meta.submitError)),
        }) }
        uploadPreviewClassName={ cn(styles.uploadPreviewContainer, uploadPreviewCls) }
        previewNameClassName={ styles.uploadedFileNameContainer }
        disabledComponent={ () => (
          <div className={ styles.disabledContainer }>
            { input.value && (
              <div className={ styles.disabledUploadPreviewContainer }>
                <ImgFetching
                  className={ uploadStyles.previewImg }
                  src={ getFilePreviewUrl(input.value.url) }
                  spinnerProps={ { overlay: true } }
                />
              </div>
            ) }
            <div className={ styles.disabledTextContainer }>
              <div className={ styles.disabledDragAndDropLabel }>Drag & drop { uploadName } or select file</div>
              <div className={ styles.disabledFileFormatsLabel }>
                { allowedFileText || UPLOAD_CONSTANTS.UPLOAD_LOGO_ALLOWED_FILES_TEXT }
              </div>
            </div>
          </div>
        ) }
        { ...otherProps }
      />
      <FormError
        { ...meta }
        { ...(touchedError && { touched: true }) }
        className={ errorClassName }
      />
      { maxSizeInfo && (
        <div className={ styles.maxSizeTip }>
          { maxSizeInfo }
        </div>
      ) }
    </FormField>
  );
};

export default UploadImageInput;
