import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { removeBreadcrumb, setBreadcrumb } from '@/condor/ui/common/Breadcrumb/actions';
import { checkTabState } from './actions';
import {
  setSettingsNeedToRefreshModalVisible,
  setMappingNeedToRefreshModalVisible,
} from './modules/DealSettings/actions';
import { setDealSlidesNeedToRefreshModalVisible } from './modules/DealSlides/actions';
import {
  isDealSettingsRefreshModalVisible,
  isRoadshowMappingRefreshModalVisible,
  isDealSlidesRefreshModalVisible,
} from './selectors';
import { push } from '@dealroadshow/connected-next-router';

import Roadshow from './Roadshow';

function mapStateToProps(state) {
  return {
    tabs: state.dealRoadshows.roadshow.tabs,
    isRoadshowSettingsNeedToRefresh: isDealSettingsRefreshModalVisible(state),
    isRoadshowMappingNeedToRefresh: isRoadshowMappingRefreshModalVisible(state),
    isRoadshowSlidesNeedToRefresh: isDealSlidesRefreshModalVisible(state),
    isFetching: state.dealRoadshows.roadshow.dealSettings.data.isFetching
                || state.dealRoadshows.roadshow.dealSlides.data.isFetching
                || state.dealRoadshows.roadshow.audioVideo.isFetching
                || state.dealRoadshows.roadshow.customModal.isFetching,
    dealSettings: state.dealRoadshows.roadshow.dealSettings.data,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    checkTabState,
    removeBreadcrumb,
    setBreadcrumb,
    setSettingsNeedToRefreshModalVisible,
    setMappingNeedToRefreshModalVisible,
    setDealSlidesNeedToRefreshModalVisible,
    push,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Roadshow);
